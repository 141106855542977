import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana24: React.FC = () => {
  const Notice1 = `Durante plenaria ordinaria del 5 de diciembre de 2024 se dio apertura al segundo debate por parte del Concejo Distrital para la aprobación del empréstito por 3.5 billones de pesos solicitado por el Alcalde Alejandro Eder en días pasados.

El Concejal del partido Alianza Verde, Rodrigo Salazar, mantuvo su postura de NO aprobar este empréstito como un acto de responsabilidad con las finanzas de la ciudad.

El cabildante expuso su negativa al Proyecto de Acuerdo argumentando los siguientes puntos:

1. Conforme a las proyecciones aportadas por la administración el indicador de sostenibilidad es superado en más de un 100% a partir del año 2025 hasta el año 2031, es decir que van a dejar el Distrito 7 años en un nivel de infringir el techo del indicador de sostenibilidad.

2. El servicio de la deuda se incrementará notoriamente a partir del año 2028, por lo que la caja remanente del Distrito será duramente afectada y esto limitará seriamente la capacidad gerencial de las administraciones venideras, poniendo en riesgo la prestación de la oferta institucional con vocación social y ambiental en la ciudad.

3. De los 32 proyectos que se pretenden apalancar a través de este empréstito, solamente 13 cuentan con viabilidad en fase 3 y suman 1.5 billones de pesos, por lo cual el concejal ha sido enfático en pedirle al Alcalde que recule, que priorice estos proyectos que se encuentran adelantados, los cuales podrían materializarse en su periodo de gobierno y que elimine del empréstito proyectos que no guardan correlación con las principales necesidades de la ciudadanía.

4. Considera el Concejal Salazar que la administración debe enfocar esfuerzos en identificar nuevas fuentes de ingresos así como de recuperación de cartera y mejoramiento de los métodos de recaudo antes de pensar en endeudar más la ciudad.

5. No se ha pensado en nuevas fuentes de ingresos corrientes que mejore las finanzas del Distrito, y en este orden de ideas, sentenció el Concejal que “no se extrañen los caleños que el día de mañana nos suban el predial de manera asfixiante a partir de actualizaciones catastrales para cubrir la deuda del empréstito.”

Finalizó su intervención con una férrea advertencia de que hará un riguroso y responsable control político a los recursos y proyectos que la administración emprenda con el empréstito y así mismo convocó a la ciudadanía a que, en ejercicio de su poder soberano, se inicie una acción ciudadana de democracia directa entorno al mecanismo de participación de REFERENDUM DEROGATORIO al Acuerdo del billonario y asfixiante empréstito que hoy se aprobó por el Concejo Distrital.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/DDPGyvhxF4-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          EL NO AL EMPRÉSTITO POR 3.5 BILLONES DE PESOS ES UN ACTO DE
          RESPONSABILIDAD CON LA CIUDAD.{" "}
        </h1>
        <h5 className={style.Fecha}>Diciembre 05 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde; Proyectó: Janner Rodríguez Losada{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana24;

import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana23: React.FC = () => {
  const Notice1 = `En el Concejo de Cali se realizó exaltación de honor a la organización 100 en 1 Día, colectivo dedicado al empoderamiento ciudadano por medio de acciones enfocadas en el medio ambiente, deporte, espacio público y cultura.
Este se define como un movimiento que busca que las personas transformen su ciudad por medio de la realización de 100 o más iniciativas en un día, en aras de generar una reconexión con los espacios públicos en Cali, labor que con jóvenes voluntarios esta organización viene desarrollando desde hace 10 años.
Entre las acciones que ha desarrollado este grupo se destacan el monumento Mira Ve ubicado en el barrio el Templete, hecho con elementos de plástico recuperado e inaugurado en 2019, en el marco de los 488 años de Cali realizaron la intervención urbanística “Puente Arcoíris”, en parque lineal del Rio Cali, pasando de ser un puente gris y olvidado ahora es un lugar vibrante. 
Rodrigo Salazar, concejal proponente resaltó que se siente muy satisfecho de brindar un reconocimiento a este grupo de jóvenes que le apuestan a plasmar cambios positivos en la forma que tiene la ciudadanía el vivir la ciudad en la que habitan, pues le recuerda mucho su etapa cuando hizo parte de grupos de juventudes.
“Hemos identificado que100 en un Día es un capitalizador de la participación ciudadana, convocando a otros colectivos a desarrollar ejercicios de responsabilidad frente al cuidado del medio ambiente y generar una ciudad más amable, por eso, en su décimo aniversario resaltamos su labor”, expresó el cabildante. 
Para Marcela Villegas, voluntaria de 100 en un Día Cali considera que es un honor haber recibido la exaltación.
“Estamos muy contentos con este enaltecimiento y nos llenad orgullo, nos motiva a seguir trabajando por una Cali más unida, participativa y llena de oportunidades para todos”, comentó Villegas.
Felipe Ramos, quien desde el año 2017 es integrante del movimiento dice que se unió a este grupo porque es un “enamorado de su ciudad” y por eso invierte su tiempo para que la gente sea consciente de lo afortunados de vivir en Cali.
“Queremos que los caleños vean el enorme potencial que tenemos, nuestro lema es pasar de la queja a la acción haciendo cosas más tangibles por la ciudad”, manifestó Ramos.
La ceremonia finalmente se cerró con la entrega de los pergaminos por parte del concejal Rodrigo Salazar y demás cabildantes.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/DCXiYPoy05L/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Merecido reconocimiento a 100 por un Día por parte del concejal
          Rodrigo Salazar{" "}
        </h1>
        <h5 className={style.Fecha}>Noviembre 14 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana23;

import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana22: React.FC = () => {
  const Notice1 = `Durante plenaria ordinaria el alcalde Alejandro Eder radicó ante el Concejo de Cali una solicitud de un empréstito por un valor a 3.5 billones de pesos, en aras de financiar el plan de desarrollo Cali, Capital Pacífica de Colombia y que ha sido motivo de debates dentro de la corporación, así mismo, la exorbitante cifra ha generado en algunos concejales preocupación frente a la propuesta.

Entre las falencias más destacadas de este Proyecto de Acuerdo 022, se encuentran la falta de información y sustentación de las obras donde se ejecutarían. 

Si bien la situación financiera de Cali es saludable, el Distrito no puede asumir una deuda de tal magnitud.

“En estos momentos el distrito está seriamente golpeado en la tributación, no tenemos un recaudo fiscal eficiente y mientras no mejoremos ese aspecto y generar nuevas fuentes de ingreso no podríamos encaminarnos en un crédito de 3.5 billones de pesos que dejaría a Cali maniatada por casi 3 futuras alcaldías”, manifestó el cabildante.

Otro punto para considerar es la falta de información y claridad del contenido del proyecto, pues existen vacíos jurídicos y que no se especifican los montos y recaudo esperado para cada proyecto contemplado, por ende, el impacto financiero podría ser más alto de los esperado, en donde en ultimas sería la propia ciudadanía en la que recaería dicha responsabilidad financiera. 

“Cali ya tiene una deude por valor a un billón y con la solicitud del alcalde Alejandro Eder por 3.5 billones de pesos al proyectarse los intereses todos los caleños estaremos pagando una suma por casi 10 billones de pesos en los siguientes 14 años pagados principalmente del impuesto predial para financiar obras que aún son ´ideas de proyectos´”, sostuvo el concejal del Partido Alianza Verde.

Por su parte la administración distrital en cabeza del Director de Hacienda Distrital, Jhon Quinchua, sostiene que Cali si cuenta con capacidad de pago suficiente. La Alcaldía de Cali ha proyectado un total de 32 obras y programas que ambicionan ser financiadas por dicho empréstito. 

Para la curul verde del concejal Rodrigo Salazar, es necesario abrir el estudio y debate del empréstito a la ciudadanía y absolver interrogantes tales como:

1.	La capacidad real de endeudamiento de Cali.
2.	El impacto negativo de la deuda en la sostenibilidad financiera. 
3.	La Dependencia creciente del endeudamiento. 
4.	Los riesgos de incumplimiento de compromisos legales y operativos.
5.	El impacto en la eficiencia fiscal y tributaria.
6.	El retorno de la inversión de los proyectos. 
7.	La priorización de proyectos entorno a su pertinencia y factibilidad de ejecutarse en el actual periodo de gobierno. 
8.	La continuidad de proyectos que actualmente se encuentren en ejecución.
Para aprobar el cupo de endeudamiento de forma responsable, es esencial que se incluyan mecanismos de control específicos que garanticen un crecimiento sostenido de los ingresos tributarios y minimicen la dependencia del crédito. 

“Desde el Concejo Distrital estamos ejerciendo un riguroso estudio de este crédito, máxime cuando esta administración ha manejado una narrativa de que Cali la dejaron solo con tres pesos y quebrada, y si esos proyectos si tienen un retorno que mejore la caja y si esos mismos proyectos tengan una rentabilidad social y ambiental”, manifestó Salazar. 

El estudio del proyecto 022 sigue su rumbo dentro del Concejo de Cali, desde la curul del Partido Alianza Verde del concejal Rodrigo Salazar, se está dando un análisis detallado del documento, de cara a la ciudadanía para que opine sobre este endeudamiento, que sea la propia comunidad quienes den a conocer en que se necesita invertir tan alta cifra, más si se tiene en cuenta que ningunos de los proyectos mencionados está listo para arrancar en el 2025.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/DCJuqiiueWQ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Empréstito de 3.5 billones solicitados por el alcalde Alejandro Eder
          ¿Revivamos Cali o endeudamos a Cali?
        </h1>
        <h5 className={style.Fecha}>Noviembre 09 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana22;
